<template>
    <dashboard-layout>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col>
                                {{ video.title }}
                            </v-col>
                            <v-col class="text-right">
                                <v-btn text :to="{name: 'artist.videos.index'}">return</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        placeholder="Video Title"
                                        label="Video Title"
                                        v-model="video.title"
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="! video.id">
                                <v-col>
                                    <vue2-dropzone
                                        ref="myVueDropzone" id="dropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-success="handleSuccess"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        :value="$date(video.created_at).format('MMM DD, YYYY HH:mm')"
                                        readonly />
                                </v-col>
                            </v-row>
                            <v-row v-if="video.file_url">
                                <v-col>
                                    <video
                                        id="player"
                                        width="100%"
                                        playsinline
                                        controls
                                        data-poster="/path/to/poster.jpg">
                                        <source :src="video.file_url"/>
                                    </video>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn @click="save">Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="video.id">
            <v-col>
                <v-card>
                    <v-card-title>
                        Plays
                    </v-card-title>
                    <v-card-subtitle>
                        {{ video.artist_video_plays_count }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </dashboard-layout>
</template>

<script>
import Video from "@/models/Video";
import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Plyr from 'plyr';
import 'plyr/dist/plyr.css'
import DashboardLayout from "@/layouts/DashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "users-show",
    components: {DashboardLayout, vue2Dropzone},
    data: function () {
        return {
            video: {},
            errors: [],
            dropzoneOptions: {
                url: '/api/upload',
                thumbnailWidth: 250,
                maxFilesize: 100,
                maxFiles: 1,
                headers: {
                    Authorization: null
                }
            }
        }
    },
    methods: {
        handleSuccess(file, res) {
            this.video.file_url = res.url
            this.video.file_path = res.path
            this.video.title = this.video.title ? this.video.title : res.original_name
            this.$forceUpdate()
        },
        async save() {
            this.errors = []

            try {
                this.video = await this.video.save()
            } catch (e) {
                this.errors = e.response.data.errors
                return
            }

            if (!this.$route.params.id) {
                await this.$router.push({name: 'artist.videos.index'})
                return
            }

            this.showSnackBar({color: 'success', timeout: 3000, text: 'Video saved'})
        },
        ...mapMutations(['showSnackBar']),
    },
    async mounted() {
        const videoId = this.$route.params.id
        this.video = videoId ? await Video.find(videoId) : new Video()

        const token = localStorage.getItem('auth_token_default')
        this.dropzoneOptions.headers.Authorization = "Bearer " + token
    },
    watch: {
        video: function (val, oldval) {
            if (val && val !== oldval) {
                new Plyr('#player');
                this.$forceUpdate()
            }
        }
    }
}
</script>
